import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {TranslationPortalFile} from "../../../utils/constants";
import {
    Alert,
    AlertItem,
    AlertSeverity,
    Box,
    BoxSpacing,
    ButtonLinkCancel,
    ButtonSave,
    FieldBlock,
    FlexContentDirection,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    FormLayoutTitle,
    InputPhone,
    InputPhoneCountry,
    InputPhoneLocale,
    InputText
} from "@sirdata/ui-lib";
import {ButtonUpdate, FormErrorMessage} from "../../../component/snippet";
import {CountryCode} from "../../../common/api/model/Country";
import {AccountType} from "../../../common/api/model/account/AccountType";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {Locale} from "../../../common/utils/Locale";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {FormLayoutMessage} from "../../../common/component/snippet";

const FormChangeAccountInfo: FunctionComponent<{ onSuccess: Function }> = ({onSuccess}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);

    const [savedAccount, setSavedAccount] = useState<PartnerAccount>();

    const [account, setAccount] = useState<PartnerAccount>();
    const [isOpenForm, setOpenForm] = useState(false);
    const [infoForm, setInfoForm] = useState<{ last_name: string; first_name: string; phone: string; phone_region: string; type: AccountType }>({
        last_name: "",
        first_name: "",
        phone: "",
        phone_region: "",
        type: AccountType.OWNER
    });
    const [errors, setErrors] = useState<{ phone?: boolean }>({phone: false});
    const [message, setMessage] = useState<AlertItem>();

    const hasEmptyField = () => !infoForm.last_name || !infoForm.first_name || !infoForm.phone || !infoForm.phone_region;

    const handleSubmit = async () => {
        try {
            setErrors({});
            let account = await session.getPartnerAccount();
            account.load({
                last_name: infoForm.last_name,
                first_name: infoForm.first_name,
                phone: infoForm.phone,
                phone_region: infoForm.phone_region || CountryCode.FRANCE.code,
                type: infoForm.type
            });

            await session.restPartnerAccount.update(account);
            setOpenForm(false);
            showMessage(textInformation("account_info_form.message.submit_success"));
            onSuccess();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.BAD_REQUEST && e.message.includes("invalid phone number")) {
                    setErrors((prevState) => ({...prevState, phone: true}));
                } else {
                    showMessage(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER);
                }
            }
        }
    };

    const handleCancel = () => {
        if (savedAccount) {
            account?.load(savedAccount);
        }
        setOpenForm(false);
    };

    const showMessage = (message: string, severity?: AlertSeverity) => {
        setMessage({text: message, severity: severity || AlertSeverity.SUCCESS});
        setTimeout(() => setMessage(undefined), 3000);
    };

    useEffect(() => {
        (async function () {
            try {
                setErrors({});
                const account = await session.getPartnerAccount();
                if (isOpenForm) {
                    setSavedAccount({...account} as PartnerAccount);
                    setErrors({});
                    setMessage(undefined);
                }
                setAccount(account);
                setInfoForm({
                    last_name: account.last_name,
                    first_name: account.first_name,
                    phone: account.phone,
                    phone_region: account.phone_region,
                    type: account.type
                });
            } catch (e) {
                showMessage("default", AlertSeverity.DANGER);
            }
        })();
    }, [isOpenForm]);

    if (!account) return <></>;

    return (
        <Box spacing={BoxSpacing.LARGE}>
            <FormLayoutRows>
                <FormLayoutTitle>{textInformation("account_info_form.title")}</FormLayoutTitle>
                {isOpenForm ?
                    <>
                        <FieldBlock label={textInformation("account_info_form.field.account_type")}>
                            {textInformation(`account_info_form.account_types.${infoForm.type}`)}
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textInformation("account_info_form.field.last_name")} required>
                                <InputText
                                    placeholder={textInformation("account_info_form.placeholder.last_name")}
                                    value={infoForm.last_name}
                                    onChange={(value) => setInfoForm((prevState) => ({...prevState, last_name: value}))}
                                    autoFocus
                                />
                            </FieldBlock>
                            <FieldBlock label={textInformation("account_info_form.field.first_name")} required>
                                <InputText
                                    placeholder={textInformation("account_info_form.placeholder.first_name")}
                                    value={infoForm.first_name}
                                    onChange={(value) => setInfoForm((prevState) => ({...prevState, first_name: value}))}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock
                            label={textInformation("account_info_form.field.phone")}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputPhone
                                placeholder={textInformation("account_info_form.placeholder.phone")}
                                value={infoForm.phone}
                                onChange={(value: string) => setInfoForm((prevState) => ({...prevState, phone: value}))}
                                locale={Locale.isFrench() ? InputPhoneLocale.FRENCH : InputPhoneLocale.ENGLISH}
                                country={infoForm.phone_region as InputPhoneCountry}
                                onCountryChange={(value) => setInfoForm((prevState) => ({...prevState, phone_region: value || ""}))}
                            />
                            {!!errors.phone &&
                                <FormLayoutMessage message={t(`error.${FormErrorMessage.PHONE_INVALID}`)} severity={AlertSeverity.DANGER}/>
                            }
                        </FieldBlock>
                        <FormLayoutMention/>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={handleCancel}/>
                            <ButtonSave onClick={handleSubmit} disabled={hasEmptyField()}/>
                        </FormLayoutButtons>
                    </> :
                    <>
                        <FieldBlock label={textInformation("account_info_form.field.account_type")}>
                            {textInformation(`account_info_form.account_types.${infoForm.type}`)}
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textInformation("account_info_form.field.last_name")}>
                                {account.last_name}
                            </FieldBlock>
                            <FieldBlock label={textInformation("account_info_form.field.first_name")}>
                                {account.first_name}
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textInformation("account_info_form.field.phone")}>
                            {account.phone}
                        </FieldBlock>
                        <FormLayoutButtons>
                            <ButtonUpdate onClick={() => setOpenForm(true)}/>
                        </FormLayoutButtons>
                    </>
                }
                {message && <Alert {...message}/>}
            </FormLayoutRows>
        </Box>
    );
};

export default FormChangeAccountInfo;
